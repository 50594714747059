<template>
  <v-dialog
    v-model="show"
    width="500"
    persistent
  >
    <v-card>
      <v-card-title class="headline primary modaltext--text">
        {{ COMMON.EDIT_AD_IMAGES }}
      </v-card-title>

      <v-form
        ref="adImageForm"
        @submit.prevent="onSubmit"
      >
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="videoId"
                :items="newsVideos"
                item-text="title"
                item-value="id"
                outlined
                dense
                background-color="white"
                label="Select Capsule"
                hide-details="auto"
                :rules="[rules.required]"
              />
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="adImage.title"
                outlined
                dense
                background-color="white"
                label="Ad title"
                hide-details="auto"
                :rules="[rules.required]"
              />
            </v-col>

            <v-col cols="12">
              <v-select
                v-model="adImage.placement"
                :items="availablePlacements"
                outlined
                dense
                background-color="white"
                label="Select Placement"
                hide-details="auto"
                :rules="[rules.required]"
              />
            </v-col>

            <v-col cols="12">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="dateRange"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    label="Select Dates"
                    prepend-inner-icon="mdi-calendar"
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    :rules="[rules.required]"
                    hide-details="auto"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="dateRange"
                  no-title
                  scrollable
                  range
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(dateRange)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="cancel"
          >
            {{ COMMON.CANCEL_BTN }}
          </v-btn>
          <v-btn
            color="primary"
            type="submit"
          >
            <span v-if="!isBusy"> Update </span>
            <v-progress-circular
              v-else
              indeterminate
            />
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import common from '../../commons/common';

export default {
  name: 'EditAdImage',

  props: {
    value: { type: Boolean },
    adImageData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      COMMON: common,
      ICON: common.ICON,

      dialog: false,
      menu: false,
      dateRange: [],
      isBusy: false,
      imageUploaded: false,
      uploading: false,
      progress: 0,
      videoId: 0,

      adImage: {
        videoId: null,
        title: '',
        url: '',
        placement: 'LEFT',
        startDate: '',
        endDate: '',
      },

      availablePlacements: ['LEFT', 'RIGHT', 'BOTH'],

      newsVideos: [],

      rules: {
        required: (value) => !!value || 'Required.',
      },
    };
  },

  computed: {
    dateRangeText() {
      return this.dateRange.join(' ~ ');
    },

    show: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      },
    },
  },

  watch: {
    adImageData: {
      immediate: true,
      deep: true,
      handler(nv) {
        this.adImage = {
          ...nv,
          videoId: nv.newsVideo.id,
          startDate: nv.startDate.substring(0, 10),
          endDate: nv.endDate.substring(0, 10),
        };
        this.videoId = nv.newsVideo.id;
        this.dateRange = [this.adImage.startDate, this.adImage.endDate];
      },
    },
    dateRange: {
      handler(nv) {
        if (!nv) return;
        this.adImage.startDate = `${nv[0]} 00:00:00`;
        this.adImage.endDate = `${nv[1]} 23:59:59`;
      },
    },
  },

  created() {
    this.getNewsVideos();
  },

  methods: {
    async getNewsVideos() {
      const { data } = await this.$axios.get('/news-videos');
      this.newsVideos = data;
    },

    onSubmit() {      
      this.adImage.videoId = [this.videoId];
      if (this.$refs.adImageForm.validate()) this.updateAdImage();
    },

    async updateAdImage() {
      try {
        this.isBusy = true;
        const { status } = await this.$axios.put(
          `/ad-images/${this.adImage.id}`,
          this.adImage
        );
        if (status === 201) {
          this.$emit('on-ad-update');
          this.$emit('enable-snack', 'Ad successfully updated');

          this.cancel();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isBusy = false;
      }
    },

    cancel() {
      this.show = false;
    },
  },
};
</script>

<style></style>
