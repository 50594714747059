<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="320"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          icon
          v-on="on"
        >
          <v-icon dark>
            {{ ICON.DELETE_TRASH }}
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="title">
          Do you want to delete {{ title }} ?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            No
          </v-btn>
          <v-btn
            color="primary"
            @click="deleteAd"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import commons from '@/commons/common';
export default {
  name: 'DeleteAd',

  props: {
    id: { type: Number },
    title: { type: String },
  },

  data() {
    return {
      COMMON: commons,
      ICON: commons.ICON,

      dialog: false,
    };
  },

  methods: {
    deleteAd() {
      this.dialog = false;
      this.$emit('on-delete', this.id);
    },
  },
};
</script>

<style></style>
