<template>
  <v-dialog
    v-model="dialog"
    width="500"
    persistent
  >
    <template #activator="{ on, attrs }">
      <v-btn
        small
        v-bind="attrs"
        v-on="on"
      >
        <v-icon small>
          {{ ICON.ADD_PLUS }}
        </v-icon>
        {{ COMMON.ADD_AD_IMAGES }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="headline primary modaltext--text">
        {{ COMMON.ADD_AD_IMAGES }}
      </v-card-title>

      <v-dialog
        v-model="uploading"
        hide-overlay
        persistent
        width="300"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-text>
            Uploading, Please wait...
            <v-progress-linear
              color="light-green darken-4"
              height="10"
              :value="progress"
              stripped
            />
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-form
        ref="adImageForm"
        @submit.prevent="onSubmit"
      >
        <v-card-text>
          <v-row>
            <v-col>
              <v-file-input
                v-model="image"
                accept="image/*"
                prepend-icon=""
                :prepend-inner-icon="ICON.IMAGE"
                outlined
                dense
                background-color="white"
                label="Select thumbnail"
                hide-details="auto"
                :rules="[rules.required]"
                @change="uploadThumbnail"
                @click:clear="adImage.thumbnailUrl = null"
              />
            </v-col>
            <template v-if="imageUploaded">
              <v-col cols="12">
                <v-select
                  v-model="adImage.videoId"
                  :items="newsVideos"
                  item-text="title"
                  item-value="id"
                  outlined
                  dense
                  background-color="white"
                  label="Select Capsule"
                  multiple
                  hide-details="auto"
                  :rules="[rules.required]"
                />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="adImage.title"
                  outlined
                  dense
                  background-color="white"
                  label="Ad title"
                  hide-details="auto"
                  :rules="[rules.required]"
                />
              </v-col>

              <v-col cols="12">
                <v-select
                  v-model="adImage.placement"
                  :items="availablePlacements"
                  outlined
                  dense
                  background-color="white"
                  label="Select Placement"
                  hide-details="auto"
                  :rules="[rules.required]"
                />
              </v-col>
             
              <v-col cols="12">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="dateRange"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateRangeText"
                      label="Select Dates"
                      prepend-inner-icon="mdi-calendar"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      :rules="[rules.required]"
                      hide-details="auto"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="dateRange"
                    no-title
                    scrollable
                    range
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(dateRange)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </template>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="cancel"
          >
            {{ COMMON.CANCEL_BTN }}
          </v-btn>
          <v-btn
            v-show="imageUploaded"
            color="primary"
            type="submit"
          >
            <span v-if="!isBusy"> Add </span>
            <v-progress-circular
              v-else
              indeterminate
            />
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import common from '../../commons/common';

export default {
  name: 'AddAdImage',

  data() {
    return {
      COMMON: common,
      ICON: common.ICON,

      dialog: false,
      menu: false,
      dateRange: [],
      isBusy: false,
      imageUploaded: false,
      uploading: false,
      progress: 0,

      adImage: {
        videoId: null,
        title: '',
        url: '',
        placement: 'LEFT',
        startDate: '',
        endDate: '',
      },

      availablePlacements: ['LEFT', 'RIGHT', 'BOTH'],

      newsVideos: [],

      rules: {
        required: (value) => !!value || 'Required.',
      },
    };
  },

  computed: {
    dateRangeText() {
      return this.dateRange.join(' ~ ');
    },
  },

  watch: {
    dateRange: {
      handler(nv) {
        this.adImage.startDate = `${nv[0]} 00:00:00`;
        this.adImage.endDate = `${nv[1]} 00:00:00`;
      },
    },
  },

  created() {
    this.getNewsVideos();
  },

  methods: {
    async getNewsVideos() {
      const { data } = await this.$axios.get('/news-videos');
      this.newsVideos = data;
    },

    async uploadThumbnail(image) {
      let newAdImage = new FormData();
      newAdImage.append('file', image);

      try {
        this.uploading = true;
        this.progress = 0;
        const { data, status } = await this.$axios.post(
          '/ad-images/thumbnail/upload',
          newAdImage,
          {
            onUploadProgress: function(e) {
              this.progress = Math.round((e.loaded / e.total) * 100);
            },
          }
        );

        if (status === 200) {
          this.adImage.url = data.url;
          this.adImage.filename = data.filename;
          this.imageUploaded = true;
          this.$emit('enable-snack', 'Image uploaded');
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.uploading = false;
      }
    },

    onSubmit() {
      if (this.$refs.adImageForm.validate()) this.addAdImage();
    },

    async addAdImage() {
      try {
        this.isBusy = true;
        const { status } = await this.$axios.post('ad-images', this.adImage);
        if (status === 201) {
          this.$emit('on-add-create');
          this.$emit('enable-snack', 'Ad successfully created');

          this.cancel();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isBusy = false;
      }
    },

    cancel() {
      this.dialog = false;
      this.videoUploaded = false;
      this.$refs.adImageForm.reset();
    },
  },
};
</script>

<style></style>
